@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



























.help-badge
  position: fixed
  bottom: 16px
  right: 16px
  box-shadow: 0 1px 16px rgba(0,0,0,.35)
  border-radius: 100px !important

  &__toggle
    border-radius: 100px !important
    background: $color-ink-light !important

    +mq-s
      padding: 0 12px !important

      .btn__icon--left
        margin-right: 0 !important
        margin-left: 0 !important

    &:hover
      transform: scale(1.03) !important

    &:focus, &:active
      background: shade-color($color-ink-light, 10%) !important
      box-shadow: none !important


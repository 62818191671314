@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/mixins";


































































.btn {
  font-weight: $font-weight-semi-bold;
  appearance: none;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    width: unset;
    height: unset;
  }

  &--loading {
    font-size: 0;
  }

  &__spinner {
    position: absolute;
  }

  &:focus {
    outline: 0
  }

  &:disabled {
    cursor: disabled;
    opacity: .5;
    pointer-events: none;
  }

  &__icon {
    display: flex;
  }

  // Sizes
  &--big {
    height: $size-l;
    padding: 0 $size-m;

    &.btn--icon-only {
      width: $size-l;
      padding: 0;
    }

    .btn__icon {

      &--left {
        margin-right: 12px;
        margin-left: -8px;
      }

      &--right {
        margin-left: 12px;
        margin-right: -8px;
      }
    }
  }

  &--regular {
    height: 36px;
    padding: 0 $size-s;

    &.btn--icon-only {
      width: 36px;
      padding: 0;
    }

    .btn__icon {

      &--left {
        margin-right: 8px;
        margin-left: -6px;
      }

      &--right {
        margin-left: 8px;
        margin-right: -6px;
      }
    }
  }

  &--small {
    height: 28px;
    padding: 0 12px;

    &.btn--icon-only {
      width: 28px;
      padding: 0;
    }

    .btn__icon {

      &--left {
        margin-right: 6px;
        margin-left: -4px;
      }

      &--right {
        margin-left: 6px;
        margin-right: -4px;
      }
    }
  }
  // Styles
  &--primary {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%,
                rgba(0, 0, 0, 0) 50%), $color-primary;
    color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 2px rgba(9, 30, 66, 0.14);
    @include anim(150ms);

    &:not(.nohover):hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%,
                  rgba(0, 0, 0, 0) 70%), darken($color-primary, 5%);
      @include anim(150ms);
    }
  }

  &--danger {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%,
                rgba(0, 0, 0, 0) 50%), $color_danger;
    color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 2px rgba(9, 30, 66, 0.14);
    @include anim(150ms);

    &:not(.nohover):hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%,
                  rgba(0, 0, 0, 0) 70%), darken($color_danger, 5%);
      @include anim(150ms)
    }
  }

  &--secondary {
    background: linear-gradient(180deg, rgba(21, 41, 75, 0) 50%,
                rgba(21, 41, 75, 0.15) 100%), #FFFFFF;
    border: 1px solid #C3C5CC;
    box-shadow: 0px 1px 2px rgba(9, 30, 66, 0.14);
    color: transparentize($color-ink, .2);
    @include anim(150ms);

    &:not(.nohover):hover {
      background: linear-gradient(180deg, rgba(21, 41, 75, 0) 50%,
                  rgba(21, 41, 75, 0.20) 100%), darken(#ffffff, 3%);
      @include anim(150ms);
    }
  }

  &--float {
    background: rgba(21, 41, 75, 0);
    color: transparentize($color-ink, .2);
    @include anim(150ms);

    &-dark {
      color: white;
      background: rgba(21, 41, 75, 0);
      @include anim(150ms);

      &:not(.nohover):hover {
        background: rgba(0, 0, 0, 0.2);
        @include anim(150ms);

        @include mq-l {
          background: unset;
        }
      }
    }

    &.btn--small {
      padding: 0 8px;
    }

    &.btn--regular {
      padding: 0 10px;
    }

    &:not(.nohover):hover {
      background: rgba(0, 0, 0, 0.2);
      @include anim(150ms);

      @include mq-l {
        background: unset;
      }
    }
  }

  &--icon-only {
    font-size: 0;
  }
}

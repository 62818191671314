@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"















































































































































































.app-main

  &__loading
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: calc(100vh - 56px)

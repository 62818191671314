@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

















































































.modal
  background: #ffffff
  border-radius: 12px
  min-width: 500px
  max-height: 100%
  backface-visibility: hidden
  overflow-x: auto
  overflow-y: hidden
  transition: all .3s ease
  position: relative
  z-index: 3

  +mq_modal
    min-width: auto
    width: 100vw
    height: 100vh
    border-radius: 0
    padding: $size-m
    overflow-y: scroll
    scroll-behavior: smooth

  +mq-xs
    padding: 16px

  &__container
    backface-visibility: hidden
    position: fixed
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    z-index: 9999
    display: flex
    align-items: center
    justify-content: center

  &__title
    margin-bottom: $size-m
    color: $color-ink

    +mq-s
      margin-top: $size-m

    +mq-xs
      margin-top: $size-l

  &__footer
    display: flex
    background: #ffffff

    .btn ~ .btn
      margin-left: $size-s

    +mq_modal
      position: fixed
      bottom: 0
      width: 100%
      left: 0
      box-sizing: border-box
      box-shadow: 0 -1px 12px rgba(0,0,0,.1)
      padding: 8px 16px
      z-index: 100

  &__options
    display: flex
    align-items: center

    &__item

      & ~ &
        margin-left: $size-m

  &__overlay
    display: flex
    top: 0
    left: 0
    bottom: 0
    right: 0
    position: fixed
    align-items: center
    backface-visibility: hidden
    justify-content: center
    background: rgba(0,0,0,.5)
    transition: opacity .4s ease

  &__close
    position: absolute
    right: 8px
    top: 8px
    opacity: .67


@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/mixins";

































































@import '~@/styles/main';

body {
  background: $color-ice;
  overflow-x: hidden;

  &.lock-scroll {
    overflow: hidden;
  }

  #app.--has-menu {
    padding-top: $size-xxl;
  }
}

// CARD
.card
  backface-visibility: hidden

  // moving
  &-move
    transition: all 600ms ease-in-out 50ms

  // appearing
  &-enter-active
    transition: all 400ms ease-out

  // disappearing
  &-leave-active
    transition: all 200ms ease-in
    position: absolute
    z-index: 0

  // appear at / disappear to
  &-enter,
  &-leave-to
    opacity: 0
    transform-origin: top
    transform: scale(0.8)

.list
  backface-visibility: hidden

  // moving
  &-move
    transition: all 300ms ease-in-out 5ms

  // appearing
  &-enter-active
    transition: all 400ms ease-out
    transform: scale(1)

  // disappearing
  &-leave-active
    transition: all 200ms ease-in
    position: absolute
    z-index: 0

  // appear at / disappear to
  &-enter,
  &-leave-to
    opacity: 0
    transform-origin: top
    transform: scale(0.8)

.moveUp
  &-enter-active
    transition: all 200ms ease-in-out

  &-leave-active
    transition: all 200ms ease-in-out

  &-enter, &-leave-to
    transform: translateY(100%)
    opacity: 0

.moveDown
  &-enter-active
    transition: all 200ms ease-in-out

  &-leave-active
    transition: all 200ms ease-in-out

  &-enter, &-leave-to
    transform: translateY(-100%)
    opacity: 0

.fade
  &-enter-active,
  &-leave-active
    transition: opacity 1.0s

  &-enter,
  &-leave-to
    opacity: 0

.dropdown
  &-enter-active
    transition: all 300ms ease-in-out

  &-leave-active
    transition: all 300ms ease-in-out

  &-enter, &-leave-to
    transform-origin: top right
    transform: scale(.9)
    opacity: 0

.slide
  &-leave-active,
  &-enter-active
    transition: 0.5s

  &-enter
    transform: translate(100%, 0)

  &-leave-to
    transform: translate(100%, 0)

.slideUp
  &-enter, &-leave-to
    opacity: 0

  &-enter-active
    animation: slide-up 400ms ease

  &-leave-active
    animation: slide-down 400ms ease

.modal
  // Transition Animations
  &-enter
    opacity: 0

  &-enter-active,
  &-leave-active
    opacity: 0

  &-enter &,
  &-leave-active &
    transform: scale(0.92) translateY(25%)

    +mq-s
      transform: translateY(50%)


@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(100%)

  50%
    transform: translateY(113%)

  100%
    opacity: 1
    transform: translateY(0%)


@keyframes slide-down
  0%
    opacity: 1
    transform: translateY(0%)

  100%
    opacity: 0
    transform: translateY(100%)
